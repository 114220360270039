import * as Sentry from "@sentry/browser"

if (process.env.ENABLE_FRONTEND_ERROR_TRACKING == 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE,

    maxBreadcrumbs: 50,
  });

  window.Sentry = Sentry
}


import { Helpers, Polyfills } from 'pistachio'

Polyfills.checkAndRun(main)

function main(err) {

  require('pistachio/app/components')

  require('../component_styles')
  require('../stimulus_controllers')


  window.onload = function() {
    Helpers.clarifyEmailAddresses()
    Helpers.deobfuscateAttributesOnPage()
    Helpers.pollAndForward()
  }
}

import 'pistachio/app/base'
import '../globals.css'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_asset_tag helper in views (e.g <%= image_asset_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
import * as images from '../images/**/*'
images.default.map((image) => image)
